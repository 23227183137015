
import LazyLoad from '../../../../../Partials/LazyLoad/LazyLoad';
const LaunchPadDashboard = LazyLoad(() => import('./LaunchPadDashboard'));
const HeroSubTexts = LazyLoad(() => import('./HeroSubTexts/HeroSubTexts'));
const LPTestimonials = LazyLoad(() => import('./LPTestimonials/LPTestimonials'));
const Clients = LazyLoad(() => import('./Clients/Clients'));
const ClientHighlights = LazyLoad(() => import('./ClientHighlights/ClientHighlights'));
const LPCaseStudy = LazyLoad(() => import('./LPCaseStudy/LPCaseStudy'));
const LPResources = LazyLoad(() => import('./Resources/LPResources'));
const LaunchpadConsultation = LazyLoad(() => import('./LaunchpadConsultation/LaunchpadConsultation'));
const HiringRequests = LazyLoad(() => import('./HiringRequests/HiringRequests'));

const LaunchPadRoutes = [
  {
    path: 'dashboard',
    element: <LaunchPadDashboard />,
  },
  {
    path: 'heroSubTexts',
    element: <HeroSubTexts />,
  },
  {
    path: 'testimonials',
    element: <LPTestimonials />,
  },
  {
    path: 'clients',
    element: <Clients />,
  },
  {
    path: 'clientHighlights',
    element: <ClientHighlights />,
  },
  {
    path: 'caseStudies',
    element: <LPCaseStudy />,
  },
  {
    path: 'resources',
    element: <LPResources />,
  },
  {
    path: 'launchpadConsultations',
    element: <LaunchpadConsultation />,
  },
  {
    path: 'getInTouchRequests',
    element: <LaunchpadConsultation />,
  },
  {
    path: 'hiringRequests',
    element: <HiringRequests />,
  },

];

export default LaunchPadRoutes;
