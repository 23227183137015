import { RiUser2Fill } from "react-icons/ri"
import { NavCardList, NavCard } from 'anthill-component-library/layouts/master'

export const marketingRoute ='/main/marketing/'

function MarketingDashboard() {
    return (
        <NavCardList numOfCards='two'>
            <NavCard cardName='Website Content' navCardLink={marketingRoute + 'websiteContent/dashboard'}><RiUser2Fill /></NavCard>
            <NavCard cardName='SuccessStories' navCardLink={marketingRoute + 'successStories'}><RiUser2Fill /></NavCard>
        </NavCardList>
    )
}

export default MarketingDashboard