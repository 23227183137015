import { RiMapLine } from 'react-icons/ri';
import { NavCardList, NavCard } from 'anthill-component-library/layouts/master';
import { websiteContentRoute } from '../WebsiteContentDashboard';

export const launchPadRoute =websiteContentRoute + 'launchPad/'


function LaunchPadDashboard() {
  return (
    <NavCardList numOfCards={'ten'}>
      <NavCard
        cardName={'Hero Sub Text'}
        navCardLink={launchPadRoute + 'heroSubTexts'}
      >
        <RiMapLine />
      </NavCard>
      <NavCard
        cardName={'Testimonials'}
        navCardLink={launchPadRoute + 'testimonials'}
      >
        <RiMapLine />
      </NavCard>
      <NavCard
        cardName={'Clients'}
        navCardLink={launchPadRoute + 'clients'}
      >
        <RiMapLine />
      </NavCard>
      <NavCard
        cardName={'Client Highlights'}
        navCardLink={launchPadRoute + 'clientHighlights'}
      >
        <RiMapLine />
      </NavCard>
      <NavCard
        cardName={'Case Studies'}
        navCardLink={launchPadRoute + 'caseStudies'}
      >
        <RiMapLine />
      </NavCard>
      <NavCard
        cardName={'Resources'}
        navCardLink={launchPadRoute + 'resources'}
      >
        <RiMapLine />
      </NavCard>
      <NavCard
        cardName={'Consultancy Requests'}
        navCardLink={launchPadRoute + 'launchpadConsultations'}
      >
        <RiMapLine />
      </NavCard>

      <NavCard
        cardName={'Get In Touch Requests'}
        navCardLink={launchPadRoute + 'getInTouchRequests'}
      >
        <RiMapLine />
      </NavCard>

      <NavCard
        cardName={'Hiring Requests'}
        navCardLink={launchPadRoute + 'hiringRequests'}
      >
        <RiMapLine />
      </NavCard>
    </NavCardList>
  );
}

export default LaunchPadDashboard;
