import { RiUser2Fill } from "react-icons/ri"
import { NavCardList, NavCard } from 'anthill-component-library/layouts/master'
import { marketingRoute } from "../MarketingDashboard"

export const websiteContentRoute =marketingRoute + 'websiteContent/'

function WebsiteContentDashboard() {
    return (
        <NavCardList numOfCards='two'>
            <NavCard cardName=' Old Website Content' navCardLink={websiteContentRoute + 'oldWebsiteContent/dashboard'}><RiUser2Fill /></NavCard>
            <NavCard cardName='Launch Pad' navCardLink={websiteContentRoute + 'launchPad/dashboard'}><RiUser2Fill /></NavCard>
        </NavCardList>
    )
}

export default WebsiteContentDashboard