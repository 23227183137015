import { takaFormatter } from 'antopolis-utils/helpers/Formatter';
import React from 'react';

export default function TargetAchieved({ targetAmount, targetPercentage }) {
  return (
    <>
      <div
        className='target-line'
        style={{
          left: `${targetPercentage}%`,
        }}
        title={`Target Amount: ${takaFormatter(targetAmount)}`}
      ></div>
      <span
        className='target-amount'
        style={{
          left: `${targetPercentage - 2.25}%`,
        }}
      >
        {takaFormatter(targetAmount)}
      </span>
    </>
  );
}
