import { useEffect, useState } from 'react';
import {
  DateInput,
  NumberInput,
  SelectInput,
  SelectOption,
  ShortTextInput,
} from 'anthill-component-library/dist/inputs';
import { Form } from 'anthill-component-library/dist/layouts';
import { useAxiosInstance } from 'anthill-component-library/dist/hooks';
import { FormSubmitButton } from 'anthill-component-library/dist/buttons';
import ErrorMessage from '../../../../../Partials/ErrorMessage/ErrorMessage';
import { CASH_TARGET_APIS } from '../../../../../../Utilities/APIs/Gamma/Finance/CashTarget/CashTargetApi';
import { CLUseLocation } from 'anthill-component-library/dist/helpers';
import { REVENUE_TARGET_APIS } from '../../../../../../Utilities/APIs/Gamma/RevenuTarget/RevenueTargetAPIs';
import { monthsArray } from '../../Utils/monthData';

function UpdateEntry({
  editableData,
  triggerFetch,
  setShowUpdateForm,
  targetID,
  setShowArchiveForm,
  year,
  month,
}) {
  const [name, setName] = useState(editableData?.name);
  const [amount, setAmount] = useState(editableData?.amount);
  const [updatedYear, setUpdatedYear] = useState(year);
  const [updatedMonth, setUpdatedMonth] = useState(month);
  const [availableMonths, setAvailableMonths] = useState([]);
  const [probability, setProbability] = useState(editableData?.probability);
  const [date, setDate] = useState(editableData?.date?.split('T')[0]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState(null);
  const axiosInstance = useAxiosInstance();

  const { pathname } = CLUseLocation();
  const isCashTarget = pathname === '/main/finance/cashTarget';
  const api = isCashTarget
    ? CASH_TARGET_APIS + 'updateCashTargetEntry/'
    : REVENUE_TARGET_APIS + 'updateRevenueTargetEntry/';
  const fetchMonthApi = isCashTarget
    ? CASH_TARGET_APIS + 'fetchAvailableMonths/'
    : REVENUE_TARGET_APIS + 'fetchAvailableMonths/';

  async function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    if (
      !name ||
      !amount ||
      !probability ||
      !date ||
      !updatedYear ||
      !updatedMonth
    )
      return setError(
        'Must Add (Name,  Amount, Probability, Date, Year, Month)',
      );

    // setIsDisabled(true);

    const itemData = {
      name,
      amount,
      probability,
      date,
      month: parseInt(updatedMonth),
      year: parseInt(updatedYear),
    };
    try {
      const response = await axiosInstance.patch(
        api + `${targetID}/${editableData?._id}`,
        itemData,
      );
      if (response) {
        triggerFetch();
        setShowUpdateForm(false);
        setIsDisabled(false);
        setError('');
      }
    } catch (error) {
      setIsDisabled(false);
      if (error.response) {
        setError(
          error.response.data.message || 'An error occurred. Please try again.',
        );
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  }

  const handleArchive = async () => {
    setShowUpdateForm(false);
    setShowArchiveForm(true);
    setIsDisabled(false);
  };

  useEffect(() => {
    const fetchAvailableMonths = async () => {
      if (updatedYear === year) {
        setUpdatedMonth(month);
      } else {
        setUpdatedMonth('');
      }
      const response = await axiosInstance.get(fetchMonthApi + updatedYear);
      if (response) {
        setAvailableMonths(response.data);
      }
    };
    fetchAvailableMonths();
  }, [updatedYear, axiosInstance, fetchMonthApi]);

  return (
    <div className='crate_form'>
      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label={`Item Name`}
          value={name}
          placeholder={`Enter Name`}
          setState={setName}
        />
        <NumberInput
          label={`Item Amount`}
          value={amount}
          placeholder={`Enter Item Amount`}
          setState={setAmount}
        />
        <SelectInput
          label={'Select Probability'}
          value={probability}
          setState={setProbability}
        >
          <SelectOption optionValue='Locked' optionText='Locked' />
          <SelectOption optionValue='High' optionText='High' />
          <SelectOption optionValue='Low' optionText='Low' />
          <SelectOption optionValue='Potential' optionText='Potential' />
        </SelectInput>
        <SelectInput
          label={'Select Year'}
          value={updatedYear}
          setState={setUpdatedYear}
        >
          {['2024', '2025', '2026', '2027'].map((selectedYear, idx) => (
            <SelectOption
              key={idx}
              optionValue={selectedYear}
              optionText={selectedYear}
            />
          ))}
        </SelectInput>

        {availableMonths?.length > 0 && (
          <SelectInput
            label={'Select Month'}
            value={updatedMonth}
            setState={setUpdatedMonth}
          >
            {availableMonths.map((availableMonth, idx) => (
              <SelectOption
                key={idx}
                optionValue={availableMonth?.month}
                optionText={monthsArray[availableMonth?.month]}
              />
            ))}
          </SelectInput>
        )}

        <DateInput label={'Select Date'} value={date} setState={setDate} />

        <ErrorMessage error={error} />

        <FormSubmitButton text='Update' disabled={isDisabled} />
        <div className='btn_wrapper'>
          <button
            style={{
              backgroundColor: 'darkred',
              color: 'white',
              marginTop: '1rem',
            }}
            type='button'
            className='submit w_100'
            onClick={handleArchive}
            disabled={isDisabled}
          >
            Archive
          </button>
        </div>
      </Form>
    </div>
    // </div>
  );
}

export default UpdateEntry;
