import React from 'react';
import EntriesData from './EntriesData';
import EntriesHeader from './EntriesHeader';
import ViewOrAddEntry from './ViewOrAddEntry';

export default function EntriesContainer({
  probabilities,
  filter,
  amount,
  data,
  setShowCreateForm,
  setShowUpdateForm,
  setEditableData,
  setViewForm,
  handleFilter,
}) {
  return (
    <div className='entry-grid-container'>
      {probabilities.map((probability, index) => (
        <div key={probability} className='grid-section'>
          <EntriesHeader
            probability={probability}
            amount={amount}
            index={index}
            filter={filter}
            handleFilter={handleFilter}
          />
          <EntriesData
            data={data}
            probability={probability}
            setShowUpdateForm={setShowUpdateForm}
            setEditableData={setEditableData}
          />
          <ViewOrAddEntry
            probability={probability}
            data={data}
            setShowCreateForm={setShowCreateForm}
            setEditableData={setEditableData}
            setViewForm={setViewForm}
          />
        </div>
      ))}
    </div>
  );
}
