import { getDaysInMonth } from "antopolis-utils/helpers/DateTime"
import { useState, useEffect } from "react"


function useDate() {

    //date select
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())
    const [selectedDate, setSelectedDate] = useState(new Date().getDate())
    const [todayDate, setTodayDate] = useState(new Date());
    const [isToday, setIsToday] = useState(true)
    const [daysInMonth, setDaysInMonth] = useState([])



    const fullDate = `${selectedYear}-${parseInt(selectedMonth) + 1}-${selectedDate < 10 ? '0' + selectedDate : selectedDate}`

    useEffect(() => {

        setDaysInMonth(getDaysInMonth(parseInt(selectedMonth), parseInt(selectedYear)))

    }, [selectedMonth, selectedYear])

    useEffect(() => {
       
        const isDifferent = (
            todayDate.getFullYear() !== parseInt(selectedYear) ||
            todayDate.getMonth() !== parseInt(selectedMonth) ||
            todayDate.getDate() !== parseInt(selectedDate)
        );

        if (isDifferent) {
            setIsToday(false)
        } else {
            setIsToday(true)
        }
    }, [todayDate, selectedYear, selectedMonth, selectedDate]);



    return ({
        selectedYear, setSelectedYear,
        selectedMonth, setSelectedMonth,
        selectedDate, setSelectedDate,
        daysInMonth, setDaysInMonth,
        fullDate, isToday,
    }
    )
}

export default useDate