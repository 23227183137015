import React from 'react';

export default function AmountProgressPercentage({
  classIdentifier,
  width,
  left,
}) {
  return (
    <div
      className={`progress-bar ${classIdentifier}`}
      style={{
        width: `${width}%`,
        left: `${left}%`,
      }}
    />
  );
}
