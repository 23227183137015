import { calculateAmountProgress } from '../ProgressBar/utils/calculateAmountProgress';
import { CLUseLocation } from 'anthill-component-library/dist/helpers';
import EntriesContainer from './Components/EntriesContainer';
import ProgressBar from '../ProgressBar/Progressbar';
import { monthsMap } from '../../Utils/monthData';
import { useState } from 'react';
import './TargetEntries.css';

function TargetEntries({
  data,
  setShowCreateForm,
  setShowUpdateForm,
  setEditableData,
  setViewForm,
  month,
  setMonth,
  year,
  setYear,
}) {
  const [filter, setFilter] = useState('Locked');
  const { pathname } = CLUseLocation();

  const isCashTarget = pathname === '/main/finance/cashTarget';
  const isRevenueTarget = pathname === '/main/finance/revenueTarget';

  if (isCashTarget && !data?.cashTarget) {
    return null;
  }

  if (isRevenueTarget && !data?.revenueTarget) {
    return null;
  }

  const handleFilter = (probability) => {
    switch (probability) {
      case 'Locked':
        setFilter('Locked');
        break;
      case 'High':
        setFilter('High');
        break;
      case 'Low':
        setFilter('Low');
        break;
      case 'Potential':
        setFilter('Potential');
        break;
      default:
        setFilter('Locked');
        break;
    }
  };
  const { amount } = calculateAmountProgress(
    [],
    data?.cashTargetEntries?.filter((entry) => entry.isActive),
  );

  const probabilities = ['Locked', 'High', 'Low', 'Potential'];

  return (
    <>
      <ProgressBar
        data={data?.cashTargetEntries?.filter((entry) => entry.isActive)}
        target={data?.cashTarget || data?.revenueTarget}
        year={data?.year || year}
        month={data?.month || monthsMap[month]}
        filter={filter}
      />

      <div
        className='data_table_inner cash-target-data_table_inner'
        style={{ height: '66dvh' }}
      >
        <EntriesContainer
          probabilities={probabilities}
          filter={filter}
          amount={amount}
          data={data}
          setShowCreateForm={setShowCreateForm}
          setShowUpdateForm={setShowUpdateForm}
          setEditableData={setEditableData}
          setViewForm={setViewForm}
          handleFilter={handleFilter}
        />
      </div>
    </>
  );
}

export default TargetEntries;
