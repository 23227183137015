import { useEffect, useState } from 'react';
import { calculateDateProgress } from './utils/calculateDateProgress';
import { filterEntries } from './utils/filterEntries';
import { calculateAmountProgress } from './utils/calculateAmountProgress';
import DateProgress from './DateProgress/DateProgress';
import AmountProgress from './AmountProgress/AmountProgress';
import './Progressbar.css';

function ProgressBar({ target, data, year, month, filter }) {
  const [dateProgress, setDateProgress] = useState(0.5);

  const [filteredEntries, setFilteredEntries] = useState(data);

  useEffect(() => {
    calculateDateProgress(setDateProgress);
    setFilteredEntries(filterEntries(data, filter));
  }, [filter, data]);

  const {
    baseAmountDivided,
    lockedProgress,
    lowProgress,
    potentialProgress,
    highProgress,
    amount: amounts,
  } = calculateAmountProgress(data, filteredEntries, target, filter);

  return (
    <div className='progress-holder text-white'>
      <div className='progress-header'>Date</div>
      <DateProgress dateProgress={dateProgress} year={year} month={month} />
      <div className='progress-header'>Amount</div>
      <AmountProgress
        amounts={amounts}
        targetAmount={target}
        lockedProgress={lockedProgress}
        highProgress={highProgress}
        potentialProgress={potentialProgress}
        lowProgress={lowProgress}
        baseAmountDivided={baseAmountDivided}
      />
    </div>
  );
}

export default ProgressBar;
