import LazyLoad from "../../../../../Partials/LazyLoad/LazyLoad"

const Services = LazyLoad(() => import("../../../Acquisition/Services/Services"))
const FeaturedClients = LazyLoad(() => import("./FeaturedClients/FeaturedClients"))
const HomePageText = LazyLoad(() => import("./HomePageText/HomePageText"))
const Testimonials = LazyLoad(() => import("./Testimonials/Testimonials"))
const OldWebsiteContentDashboard = LazyLoad(() => import("./OldWebsiteContentDashboard"))

const OldWebsiteContentRoutes = [
  {
    path: 'dashboard',
    element: <OldWebsiteContentDashboard />
  },
  {
    path: 'testimonials',
    element: <Testimonials />
  },
  {
    path: 'featuredClients',
    element: <FeaturedClients />
  },
  {
    path: 'homePageText',
    element: <HomePageText />
  },
  {
    path: 'services',
    element: <Services />
  },


]

export default OldWebsiteContentRoutes