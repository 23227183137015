import AmountProgressPercentage from './Components/AmountProgressPercentage';
import AmountProgressLabel from './Components/AmountProgressLabel';
import AmountIndicator from './Components/AmountIndicator';
import TargetAchieved from './Components/TargetAchieved';
import React from 'react';
import './AmountProgress.css';

function AmountProgress({
  targetAmount,
  lockedProgress,
  highProgress,
  lowProgress,
  potentialProgress,
  baseAmountDivided,
  amounts,
  month,
  year,
}) {
  const targetPercentage = (targetAmount / baseAmountDivided) * 100;
  const progressBars = React.useMemo(
    () => [
      {
        classIdentifier: 'locked-progress',
        width: lockedProgress,
        left: 0,
        index: 0,
      },
      {
        classIdentifier: 'high-progress',
        width: highProgress,
        left: lockedProgress,
        index: 1,
      },
      {
        classIdentifier: 'low-progress',
        width: lowProgress,
        left: lockedProgress + highProgress,
        index: 2,
      },
      {
        classIdentifier: 'potential-progress',
        width: potentialProgress,
        left: lockedProgress + highProgress + lowProgress,
        index: 3,
      },
    ],
    [lockedProgress, highProgress, lowProgress, potentialProgress],
  );

  return (
    <div className='progress-container'>
      <div className='progress-bar-container'>
        {progressBars.map(({ classIdentifier, width, left, index }) => (
          <React.Fragment key={index}>
            <AmountProgressPercentage
              classIdentifier={classIdentifier}
              width={width}
              left={left}
            />
            {amounts[index] > 0 && (
              <AmountIndicator
                baseAmountDivided={baseAmountDivided}
                position={left + width - (index === 3 ? 2.5 : 1.9)}
                amount={amounts
                  .slice(0, index + 1)
                  .reduce((acc, val) => acc + val, 0)}
              />
            )}
          </React.Fragment>
        ))}

        {baseAmountDivided > targetAmount && (
          <TargetAchieved
            targetAmount={targetAmount}
            targetPercentage={targetPercentage}
          />
        )}
      </div>
      <AmountProgressLabel baseAmountDivided={baseAmountDivided} />
    </div>
  );
}

export default AmountProgress;
