import { takaFormatter } from 'antopolis-utils/helpers/Formatter';
import React from 'react';

export default function AmountIndicator({ amount, position, baseAmountDivided }) {
  if(baseAmountDivided === amount) return null;
  return (
    <span className='amount-zone' style={{ left: `${position}%` }}>
      {takaFormatter(amount)}
    </span>
  );
}
