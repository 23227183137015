import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad"
import { launchPadRoute } from "./LaunchPad/LaunchPadDashboard"
import LaunchPadRoutes from "./LaunchPad/LaunchPadRoutes"
import OldWebsiteContentRoutes from "./OldWebsiteContent/OldWebsiteContentRoutes"

const WebsiteContentDashboard = LazyLoad(() => import("./WebsiteContentDashboard"))
const OldWebsiteContentScreens = LazyLoad(() => import("./OldWebsiteContent/OldWebsiteContentScreens"))
const LaunchPadScreens = LazyLoad(() => import("./LaunchPad/LaunchPadScreens"))

const WebsiteContentRoutes = [
  {
    path: 'dashboard',
    element: <WebsiteContentDashboard />
  },
  {
    path: 'oldWebsiteContent',
    element: <OldWebsiteContentScreens />,
    children: OldWebsiteContentRoutes
  },
  {
    path: 'launchPad',
    element: <LaunchPadScreens />,
    children: LaunchPadRoutes
  },


]

export default WebsiteContentRoutes