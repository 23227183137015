import './DateProgress.css';
function DateProgress({ dateProgress, year, month }) {
  return (
    <div className='progress-container'>
      <div className='progress-bar-container'>
        <div
          className='progress-bar date-progress-bar'
          style={{ width: `${dateProgress}%` }}
        />
      </div>
      <div className='progress-labels'>
        {Array.from(
          { length: new Date(year, month + 1, 0).getDate() },
          (_, i) => i + 1,
        ).map((day) => (
          <div key={day} className='label'>
            {day}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DateProgress;
