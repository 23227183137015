import { RiAddCircleFill, RiEditCircleFill, RiPencilLine } from 'react-icons/ri';
import HeaderWrapper from '../../../../../Partials/HeaderWithFilterButton/HeaderWrapper';
import HeadingText from '../../../../../Partials/HeaderWithFilterButton/HeadingText/HeadingText';
import SelectFiltering from '../../../../../Partials/SelectFiltering/SelectFiltering';
import { monthsArray, monthsMap } from '../../Utils/monthData';
import {
  CLUseLocation,
  CLUseNavigate,
} from 'anthill-component-library/dist/helpers';
import { getHeadingText } from '../../Utils/getHeadingText';
import { takaFormatter } from 'antopolis-utils/helpers/Formatter';
import { IoMdSwitch } from 'react-icons/io';

function Heading({
  data,
  month,
  year,
  setMonth,
  setYear,
  setcashTargetCreateForm,
  setcashTargetUpdateForm,
  setEditableData,
}) {
  const { pathname } = CLUseLocation();
  const headingText = getHeadingText(pathname);
  const navigate = CLUseNavigate();
  const handleNavigate = () => {
    navigate(
      `/main/finance/${
        headingText === 'Cash Target' ? 'revenue' : 'cash'
      }Target`,
      { state: { year, month } },
    );
  };

  return (
    <HeaderWrapper>
      <div className='switch-cash-target-revenue'>
        <HeadingText headingText={headingText} />
        <button
          title={`Switch To ${
            headingText === 'Cash Target' ? 'Revenue' : 'Cash'
          } Target`}
          className='btn_create'
          onClick={handleNavigate}
        >
          <IoMdSwitch />
        </button>
      </div>
      <div className='filter-year-month'>
        <SelectFiltering
          value={year}
          setState={setYear}
          values={['2024', '2025', '2026', '2027']}
        />
        <SelectFiltering
          value={month}
          setState={setMonth}
          values={monthsArray}
        />
      </div>
      {data?.month === monthsMap[month] && data?.year === year ? (
        <div className='text-white cash_target add_cash_target'>
          <span>
            {`Target: ${
              data?.cashTarget != null
                ? takaFormatter(data.cashTarget)
                : takaFormatter(data?.revenueTarget)
            }`}
          </span>
          <button
            className='btn_create'
            onClick={() => {
              setEditableData(data);
              setcashTargetUpdateForm(true);
              setcashTargetCreateForm(false);
            }}
          >
            <RiPencilLine />
          </button>
        </div>
      ) : (
        <div className='text-white cash_target add_cash_target'>
          <span>Not Found</span>
          <button
            className='btn_create'
            onClick={() => {
              setcashTargetCreateForm(true);
              setcashTargetUpdateForm(false);
            }}
          >
            <RiAddCircleFill />
          </button>
        </div>
      )}
    </HeaderWrapper>
  );
}

export default Heading;
