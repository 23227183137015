import React from 'react';
import { RiAddCircleFill } from 'react-icons/ri';

export default function ViewOrAddEntry({
  probability,
  data,
  setShowCreateForm,
  setEditableData,
  setViewForm,
}) {
  return (
    <div className='cash-target-btn-group'>
      <button
        className='btn_create'
        onClick={() => {
          setEditableData(probability);
          setShowCreateForm(true);
        }}
      >
        <span>{`Add Item`}</span>
        <RiAddCircleFill />
      </button>
      <button
        className='btn_create '
        onClick={() => {
          setEditableData({
            data: data?.cashTargetEntries?.filter(
              (entry) => entry?.probability === probability && entry?.isActive,
            ),
            probability,
          });
          setViewForm(true);
        }}
      >
        <span>{`See All`}</span>
      </button>
    </div>
  );
}
