import {
  CRUDTable,
  CRUDTableHeader,
  CRUDth,
  ShortTextCell,
} from 'anthill-component-library/dist/crudBuilder';
import React, { useEffect, useState } from 'react';
import FilterAccountType from './Components/FilterAccountType';
import useDate from '../../../../../../Utilities/Hooks/useDate';
import { takaFormatter } from 'antopolis-utils/helpers/Formatter';
import CashReportModals from './CashReportModals/CashReportModals';
import { useAxiosInstance } from 'anthill-component-library/dist/hooks';
import { MomentSelectLayout } from 'anthill-component-library/dist/layouts';
import useFormStateUtils from '../../../../../../Utilities/Hooks/useFormStateUtils';
import { CASH_REPORTS_APIS } from '../../../../../../Utilities/APIs/Gamma/Finance/BookKeeping/CashReport/CashReportAPIs';
import '../../../../MainScreens/HR/Attendance/AttendanceUnit/AttendanceSelect/AttendanceSelect.css';
import './CashReports.css';

export default function CashReports() {
  const axiosInstance = useAxiosInstance();

  const [initialBalances, setInitialBalances] = useState('');
  const [reports, setReports] = useState([]);
  const [filter, setFilter] = useState('all');

  const {
    selectedYear,
    setSelectedYear,
    selectedMonth,
    setSelectedMonth,
    selectedDate,
    setSelectedDate,
    daysInMonth,
    fullDate,
  } = useDate();
  const [selectedReport, setSelectedReport] = useState('');
  const { showViewSection, setShowViewSection } = useFormStateUtils();
  const handleViewCashReport = (report) => {
    setSelectedReport(report);
    setShowViewSection(true);
  };
  useEffect(() => {
    async function fetchAndSetEvents() {
      const { data } = await axiosInstance.get(
        CASH_REPORTS_APIS +
          'getAllCashReportsFromSingleDate/' +
          fullDate +
          `?show=${filter}`,
      );
      setReports(data);
    }
    fetchAndSetEvents();
  }, [axiosInstance, fullDate, filter]);
  useEffect(() => {
    async function fetchBeginningEEndingBalance() {
      const { data } = await axiosInstance.get(
        CASH_REPORTS_APIS + 'fetchBeginningEndingBalance/' + fullDate,
      );
      // console.log(data);
      setInitialBalances(data);
    }
    fetchBeginningEEndingBalance();
  }, [axiosInstance, fullDate]);
  // function triggerFetch() {
  // setToggleFetch(!toggleFetch);
  // }
  return (
    <div className='screen_wrapper'>
      <div className='screen_header cash_report_screen_header'>
        <h1 className='screen_heading'>
          Cash Report ({reports?.length} in total)
        </h1>
        <div className='options_container'>
          <FilterAccountType setFilter={setFilter} filter={filter} />
          <MomentSelectLayout
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            daysInMonth={daysInMonth}
          />
        </div>
      </div>

      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th='Entry' />
          <CRUDth th='' />
          <CRUDth th='' />
          {filter === 'all' && <CRUDth th='Account' />}
          <CRUDth th='Amount' />
          <CRUDth th='Balance' />
        </CRUDTableHeader>
        <tbody className='cash-report-tbody'>
          <tr className='cash-report-initial-balance'>
            <td>Beginning Balance</td>
            <td>{takaFormatter(initialBalances?.beginningBalance || 0)}</td>
          </tr>
          {reports?.length > 0 &&
            reports?.map((report) => (
              <tr
                onClick={() => handleViewCashReport(report)}
                className='cash-report-row'
                key={report?._id}
              >
                <td colSpan='3'>{report?.event?.description}</td>
                {filter === 'all' && <td> {report?.account?.name}</td>}
                <td
                  className={`${
                    report?.amount < 0 ? 'negative-amount' : 'positive-amount'
                  }`}
                >
                  {takaFormatter(report?.amount)}
                </td>
                <ShortTextCell text={takaFormatter(report?.totalBalance)} />
              </tr>
            ))}
          <tr className='cash-report-ending-balance'>
            <td>Ending Balance</td>
            <td>{takaFormatter(initialBalances?.endingBalance || 0)}</td>
          </tr>
        </tbody>
      </CRUDTable>
      <CashReportModals
        selectedReport={selectedReport}
        setSelectedReport={setSelectedReport}
        showViewSection={showViewSection}
        setShowViewSection={setShowViewSection}
      />
    </div>
  );
}
