import { Modal } from 'anthill-component-library/dist/elements';
import { ArchiveItem } from 'anthill-component-library/dist/ArchiveItem-e0d8fc22';

import UpdateEntry from './UpdateEntry';
import CreateEntry from './CreateEntry';
import CreateTargetForm from './CreateTargetForm';
import UpdateTargetForm from './UpdateTargetForm';
import { CLUseLocation } from 'anthill-component-library/dist/helpers';
import ViewModal from './ViewModal';
import { CASH_TARGET_APIS } from '../../../../../../Utilities/APIs/Gamma/Finance/CashTarget/CashTargetApi';
import { REVENUE_TARGET_APIS } from '../../../../../../Utilities/APIs/Gamma/RevenuTarget/RevenueTargetAPIs';

function TargetModals({
  setTargetUpdateForm,
  setTargetCreateForm,
  targetUpdateForm,
  targetCreateForm,
  editableData,
  showCreateForm,
  setShowCreateForm,
  showUpdateForm,
  setShowUpdateForm,
  showArchiveForm,
  setShowArchiveForm,
  triggerFetch,
  targetID,
  year,
  month,
  viewForm,
  setViewForm,
  axiosInstance,
}) {
  const { pathname } = CLUseLocation();
  const isCashTarget = pathname === '/main/finance/cashTarget';
  const modalHeading = isCashTarget ? 'Cash' : 'Revenue';
  const archiveApi = isCashTarget
    ? CASH_TARGET_APIS + 'archiveCashtargetEntry/'
    : REVENUE_TARGET_APIS + 'archiveRevenuetargetEntry/';

  return (
    <>
      {/* Create target */}
      {targetCreateForm && (
        <Modal
          extraClass={'small'}
          modalHeading={`Create ${modalHeading} Target`}
          setShowModalContent={setTargetCreateForm}
        >
          <CreateTargetForm
            year={year}
            month={month}
            triggerFetch={triggerFetch}
            setShowCreateForm={setTargetCreateForm}
          />
        </Modal>
      )}

      {/* Update target */}
      {targetUpdateForm && (
        <Modal
          extraClass={'small'}
          modalHeading={`Update ${modalHeading} Target`}
          setShowModalContent={setTargetUpdateForm}
        >
          <UpdateTargetForm
            targetID={targetID}
            editableData={editableData}
            triggerFetch={triggerFetch}
            setShowUpdateForm={setTargetUpdateForm}
          />
        </Modal>
      )}

      {/* Add target entry */}
      {showCreateForm && (
        <Modal
          extraClass={'small'}
          modalHeading={`Create ${modalHeading} Entry`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateEntry
            editableData={editableData}
            targetID={targetID}
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}

      {/* Update target entry */}
      {showUpdateForm && (
        <Modal
          extraClass={'small'}
          modalHeading={`Update ${modalHeading} Entry`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateEntry
            editableData={editableData}
            targetID={targetID}
            triggerFetch={triggerFetch}
            setShowUpdateForm={setShowUpdateForm}
            setShowArchiveForm={setShowArchiveForm}
            year={year}
            month={month}
          />
        </Modal>
      )}

      {viewForm && (
        <Modal
          extraClass={'medium'}
          modalHeading={`${editableData?.probability} Entries`}
          setShowModalContent={setViewForm}
        >
          <ViewModal data={editableData?.data} />
        </Modal>
      )}

      {/* Archive target entry */}
      {showArchiveForm && (
        <Modal
          extraClass={'small'}
          modalHeading={`Archive ${modalHeading} Item`}
          setShowModalContent={setShowArchiveForm}
        >
          <ArchiveItem
            api={archiveApi + `${targetID}/`}
            targetID={editableData?._id}
            setShowArchiveForm={setShowArchiveForm}
            triggerFetch={triggerFetch}
            axiosInstance={axiosInstance}
            isActive={editableData?.isActive}
          />
        </Modal>
      )}
    </>
  );
}

export default TargetModals;
