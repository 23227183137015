import { takaFormatter } from 'antopolis-utils/helpers/Formatter';
import '../../FinanceEvents/Events/ViewEvent.css';

const DataRow = ({ label, value }) => (
  <div className='ts'>
    <h1>{label}</h1>
    <p
      className={`${
        label === 'Amount'
          ? Number(value) < 0
            ? 'negative-amount'
            : 'positive-amount'
          : ''
      }`}
    >
      {label === 'Entry Type' || label === 'Entry Details'
        ? value
        : takaFormatter(value)}
    </p>
  </div>
);

function ViewCashReport({ data }) {
  return (
    <>
      <DataRow label='Entry Type' value={data?.account?.name} />
      <DataRow label='Entry Details' value={data?.event?.description} />

      <div className='transaction_amount options_container'>
        <DataRow label='Amount' value={data?.amount} />
        <DataRow label='Total' value={data?.totalBalance} />
        <DataRow label='Cash' value={data?.cashBalance} />
        <DataRow label='City Bank' value={data?.cityBankBalance} />
      </div>
    </>
  );
}

export default ViewCashReport;
