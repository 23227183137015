import { a } from "anthill-component-library/dist/SwitchInput-069b06cf";

export const calculateAmountProgress = (
  data = [],
  filteredEntries = [],
  target = 0,
  filter,
) => {
  const lockedAmount = filteredEntries
    .filter((entry) => entry.probability === 'Locked')
    .reduce((acc, entry) => acc + entry.amount, 0);
  const highAmount = filteredEntries
    .filter((entry) => entry.probability === 'High')
    .reduce((acc, entry) => acc + entry.amount, 0);
  const lowAmount = filteredEntries
    .filter((entry) => entry.probability === 'Low')
    .reduce((acc, entry) => acc + entry.amount, 0);
  const potentialAmount = filteredEntries
    .filter((entry) => entry.probability === 'Potential')
    .reduce((acc, entry) => acc + entry.amount, 0);

  const currentAmount =
    filter === 'Locked'
      ? lockedAmount
      : filter === 'High'
      ? lockedAmount+highAmount
      : filter === 'Low'
      ? lockedAmount+highAmount+lowAmount
      : lockedAmount + highAmount + lowAmount + potentialAmount;
  const baseAmount = target > currentAmount ? target : currentAmount;

  return {
    currentAmount,
    lockedProgress: (lockedAmount / baseAmount) * 100,
    lowProgress: (lowAmount / baseAmount) * 100,
    potentialProgress: (potentialAmount / baseAmount) * 100,
    highProgress: (highAmount / baseAmount) * 100,
    baseAmountDivided: baseAmount,
    amount: [lockedAmount, highAmount, lowAmount, potentialAmount],
  };
};
