import React from 'react';

export default function FilterAccountType({ filter, setFilter }) {
  return (
    <div className='cash_report_filter attendance_select_container'>
      <div className='attendance_select'>
        <select
          onChange={(e) => setFilter(e.target.value)}
          className='form-select form-select-lg'
          defaultValue={filter}
        >
          <option value='all'>All</option>
          <option value='cash'>Cash</option>
          <option value='cityBank'>City Bank</option>
        </select>
      </div>
    </div>
  );
}
