import { useState } from 'react';
import useFetchRevenueTarget from '../Utils/useFetchMonthlyTarget';
import useFormStateUtils from '../../../../../Utilities/Hooks/useFormStateUtils';
import { getCurrentMonth, monthsMap } from '../Utils/monthData';

import Heading from '../Components/Heading/Heading';
import TargetEntries from '../Components/TargetEntries/TargetEntries';
import TargetModals from '../Components/TargetModals/TargetModals';
import { CLUseLocation } from 'anthill-component-library/dist/helpers';
import SwitchMonth from '../Components/TargetEntries/SwitchMonth';

function RevenuTarget() {
  const { state } = CLUseLocation();
  const [year, setYear] = useState(state?.year || new Date().getFullYear());
  const [month, setMonth] = useState(state?.month || getCurrentMonth());
  const [cashTargetCreateForm, setcashTargetCreateForm] = useState(false);
  const [cashTargetUpdateForm, setcashTargetUpdateForm] = useState(false);
  const [editableData, setEditableData] = useState(null);

  const {
    showCreateForm,
    setShowCreateForm,
    showUpdateForm,
    setShowUpdateForm,
    showArchiveForm,
    setShowArchiveForm,
    triggerFetch,
    axiosInstance,
    toggleFetch,
  } = useFormStateUtils();

  const { data, targetID } = useFetchRevenueTarget(
    year,
    monthsMap[month],
    toggleFetch,
  );

  return (
    <section className='cash_target mx-auto'>
      <Heading
        data={data}
        month={month}
        year={year}
        setMonth={setMonth}
        setYear={setYear}
        setcashTargetCreateForm={setcashTargetCreateForm}
        setcashTargetUpdateForm={setcashTargetUpdateForm}
        setEditableData={setEditableData}
      />

      <div className='entries-container'>
        <TargetEntries
          data={data}
          setShowCreateForm={setShowCreateForm}
          setShowUpdateForm={setShowUpdateForm}
          setEditableData={setEditableData}
          month={month}
          setMonth={setMonth}
          year={year}
          setYear={setYear}
        />
        <SwitchMonth
          month={month}
          setMonth={setMonth}
          year={year}
          setYear={setYear}
        />
      </div>

      <TargetModals
        targetCreateForm={cashTargetCreateForm}
        setTargetCreateForm={setcashTargetCreateForm}
        targetUpdateForm={cashTargetUpdateForm}
        setTargetUpdateForm={setcashTargetUpdateForm}
        showCreateForm={showCreateForm}
        setShowCreateForm={setShowCreateForm}
        showUpdateForm={showUpdateForm}
        setShowUpdateForm={setShowUpdateForm}
        showArchiveForm={showArchiveForm}
        setShowArchiveForm={setShowArchiveForm}
        targetID={targetID}
        triggerFetch={triggerFetch}
        year={year}
        month={monthsMap[month]}
        editableData={editableData}
        axiosInstance={axiosInstance}
      />
    </section>
  );
}

export default RevenuTarget;
