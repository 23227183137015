import LazyLoad from '../../Partials/LazyLoad/LazyLoad';

import BizDevRoutes from './BizDev/BizDevRoutes';
import RelationshipRoutes from './Relationship/RelationshipRoutes';
import HRRoutes from './HR/HRRoutes';
import FinanceRoutes from './Finance/FinanceRoutes';
import OfficeManagementRoutes from './OfficeManagement/OfficeManagementRoutes';
import AcquisitionRoutes from './Acquisition/AcquisitionRoutes';
import MarketingRoutes from './Marketing/MarketingRoutes';
import ExecutionRoutes from './Execution/ExecutionRoutes';
import RNDRoutes from './RND/RNDRoutes';
import EmployeeProjectTechTasksRoutes from './EmployeeProjectTechTasks/EmployeeProjectTechTasksRoutes';
import LaunchPadRoutes from './Marketing/WebsiteContent/LaunchPad/LaunchPadRoutes';

const Dashboard = LazyLoad(() => import('./Dashboard/Dashboard'));
const BizDevScreens = LazyLoad(() => import('./BizDev/BizDevScreens'));
const RelationshipScreens = LazyLoad(() =>
  import('./Relationship/RelationshipScreens'),
);
const HRScreens = LazyLoad(() => import('./HR/HRScreens'));
const FinanceScreens = LazyLoad(() => import('./Finance/FinanceScreens'));
const OfficeManagementScreen = LazyLoad(() =>
  import('./OfficeManagement/OfficeManagementScreen'),
);
const AcquisitionScreens = LazyLoad(() =>
  import('./Acquisition/AcquisitionScreens'),
);
const MarketingScreens = LazyLoad(() => import('./Marketing/MarketingScreens'));
const ExecutionScreens = LazyLoad(() => import('./Execution/ExecutionScreens'));
const RNDScreens = LazyLoad(() => import('./RND/RNDScreens'));
const EmployeeProjectTechTasksScreen = LazyLoad(() =>
  import('./EmployeeProjectTechTasks/EmployeeProjectTechTasksScreen'),
);
const LaunchPadScreens = LazyLoad(() => import('./Marketing/WebsiteContent/LaunchPad/LaunchPadScreens'));
const Profile = LazyLoad(() => import('./Profile/Profile'));

const mainRoutes = [
  {
    path: 'dashboard',
    element: <Dashboard />,
  },
  {
    path: 'bizDev',
    element: <BizDevScreens />,
    children: BizDevRoutes,
  },
  {
    path: 'execution',
    element: <ExecutionScreens />,
    children: ExecutionRoutes,
  },
  {
    path: 'employeeProjectTechTasks',
    element: <EmployeeProjectTechTasksScreen />,
    children: EmployeeProjectTechTasksRoutes,
  },
  {
    path: 'relationship',
    element: <RelationshipScreens />,
    children: RelationshipRoutes,
  },
  {
    path: 'acquisition',
    element: <AcquisitionScreens />,
    children: AcquisitionRoutes,
  },
  {
    path: 'rnd',
    element: <RNDScreens />,
    children: RNDRoutes,
  },
  {
    path: 'marketing',
    element: <MarketingScreens />,
    children: MarketingRoutes,
  },
  {
    path: 'hr',
    element: <HRScreens />,
    children: HRRoutes,
  },
  {
    path: 'finance',
    element: <FinanceScreens />,
    children: FinanceRoutes,
  },
  {
    path: 'officeManagement',
    element: <OfficeManagementScreen />,
    children: OfficeManagementRoutes,
  },
  {
    path: 'launchPad',
    element: <LaunchPadScreens />,
    children: LaunchPadRoutes,
  },
  {
    path: 'settings',
    element: <Profile />,
  },
];

export default mainRoutes;
