import React from 'react';
import { takaFormatter } from 'antopolis-utils/helpers/Formatter';

export default function EntriesData({
  data,
  probability,
  setShowUpdateForm,
  setEditableData,
}) {
  return (
    <div className='grid-row-container'>
      {data?.cashTargetEntries
        ?.filter(
          (entry) => entry?.probability === probability && entry?.isActive,
        )
        ?.map((entry, index) => (
          <div
            key={index}
            className='grid-row'
            onClick={() => {
              setEditableData(entry);
              setShowUpdateForm(true);
            }}
          >
            <span>{` ${entry?.name}`}</span>
            <span>{`${takaFormatter(entry?.amount)}`}</span>
          </div>
        ))}
    </div>
  );
}
