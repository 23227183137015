import React from 'react';
import { takaFormatter } from 'antopolis-utils/helpers/Formatter';

export default function AmountProgressLabel({ baseAmountDivided }) {
  return (
    <div>
      {/* Display scale lines instead of numbers */}
      <div className='progress-labels'>
        {Array.from({ length: 11 }, (_, i) => i * 10).map((percent) => (
          <div key={percent} className='scale-line' />
        ))}
      </div>
      <span className='max-amount'>
        <div className='label'>{`${takaFormatter(baseAmountDivided)}`}</div>
      </span>
    </div>
  );
}
