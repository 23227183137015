import { Modal } from 'anthill-component-library/dist/elements';
import ViewCashReport from './ViewCashReport';

function CashReportModals({
  selectedReport,
  setSelectedReport,
  showViewSection,
  setShowViewSection,
}) {
  return (
    <>
      {showViewSection && (
        <Modal
          extraClass={'small'}
          modalHeading='Cash Report'
          setShowModalContent={setShowViewSection}
        >
          <ViewCashReport data={selectedReport} />
        </Modal>
      )}
    </>
  );
}

export default CashReportModals;
