import { takaFormatter } from 'antopolis-utils/helpers/Formatter';
import React from 'react';

export default function EntriesHeader({
  probability,
  amount,
  index,
  filter,
  handleFilter,
}) {
  return (
    <div
      className={`grid-title heading-${probability} ${
        probability === filter && `active-${filter}`
      }`}
      onClick={() => handleFilter(probability)}
    >
      {probability} ({`${takaFormatter(amount[index])}`})
    </div>
  );
}
